import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import * as Icon from "react-feather"
import Feedback from "../components/Common/Feedback"

import WorksImage1 from "../assets/images/works-image/works-image1.png"
import WorksImage2 from "../assets/images/works-image/works-image2.jpg"
import WorksImage3 from "../assets/images/works-image/works-image3.png"
import WorksImage4 from "../assets/images/works-image/works-image4.jpg"

function handleClick() {
  window._klOnsite = window._klOnsite || []
  window._klOnsite.push(["openForm", "Yfyvm4"])
}

const ProjectDetails = () => (
  <Layout>
    <SEO title="ChatGPT Enabled Apps" />

    <Navbar />

    <PageBanner pageTitle="Leverage ChatGPT in Your Business Solution" />

    <div className="project-details-area ptb-80">
      <div className="container">
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <div onClick={handleClick} className="btn btn-secondary">
            Get Started
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="project-details-desc">
              <h3>Tailored ChatGPT Development to Supercharge Your Business</h3>

              <p>
                Are you looking to build custom AI-enabled apps that provide
                your users with a personalized experience? At Faraya LLC, we
                specialize in leveraging the ChatGPT Developer API to build
                custom solutions that meet your unique business needs.
              </p>

              <p>
                Our team of experts has years of experience in building
                AI-enabled applications from scratch, as well as enhancing
                existing ones. We believe that AI can help you achieve your
                business goals by providing personalized recommendations,
                streamlining workflows, and enhancing the overall user
                experience.
              </p>
              <p>
                With the ChatGPT Developer API, we can now help you rapidly add
                cutting-edge AI capabilities to your applications, including
                natural language processing, sentiment analysis, and text
                classification. Our solutions are designed to be simple,
                intuitive, and easy to use, so you can focus on what matters
                most - your business.
              </p>
              <p>
                At Faraya LLC, we understand that every business is unique, and
                that's why we take a personalized approach to every project we
                undertake. Our team will work with you to understand your
                business goals and provide you with a custom solution that meets
                your unique needs.
              </p>
              <p>
                Whether you're looking to build a chatbot, analyze customer
                feedback, or automate workflows, we can help you achieve your
                goals with the power of AI. Don't let your applications fall
                behind the competition. Let us help you build custom AI-enabled
                apps that provide your users with an unparalleled experience.
              </p>
              <p>
                Ready to get started? Contact us today to schedule a free
                consultation. Our team of experts will work with you to
                understand your needs and provide you with a custom quote for
                your project.
              </p>
              <p>
                Leverage the power of ChatGPT Developer API to build custom
                AI-enabled apps that help you achieve your business goals.
                Contact us today to get started!
              </p>
              <p>
                We also helped develop <a href="https://primer.net/">Primer</a>,
                a comprehensive AI-enabled platform for education and
                productivity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Feedback />
    <Footer />
  </Layout>
)

export default ProjectDetails
